import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SelectCompanyDialogModule } from '@shared/dialogs/select-company-dialog/select-company-dialog.module';
import { AffixModule } from '@shared/directives/affix/affix.module';
import { PipeFunctionModule } from '@shared/pipe-function/pipe-function.module';
import { PipesModule } from '@shared/pipes';
import { NgxFloatUiModule } from 'ngx-float-ui';

import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { TopNavComponent } from './main-nav-wrap/main-nav-wrap.component';
import { MainNavMenuPanelComponent } from './navigation/main-nav-menu-panel/main-nav-menu-panel.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { TabsNavContainerComponent } from './tabs-nav-container/tabs-nav-container.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';


@NgModule( {
  declarations: [
    BaseLayoutComponent,
    ProfileMenuComponent,
    NavigationComponent,
    TopNavComponent,
    MainNavMenuPanelComponent,
    TabsNavContainerComponent,
    UserNotificationsComponent,
  ],
  imports:      [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    PipesModule,
    MatProgressBarModule,
    MatDialogModule,
    SelectCompanyDialogModule,
    NgxFloatUiModule,
    PipeFunctionModule,
    AffixModule,
    MatDividerModule,
  ],
  exports:      [
    UserNotificationsComponent,
  ],
} )
export class AppLayoutModule {
}
