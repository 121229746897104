<div id="app-wrapper" class="base-content-grid">
  <div id="page-loader" class="base-full-width">
    @if (loading$ | async) {
      <mat-progress-bar mode="indeterminate" class="breakout"></mat-progress-bar>
    }
  </div>

  @if ($isLoggedIn | async) {
    <ume-main-nav-wrap id="ume-top" class="base-full-width">
      <ume-navigation
        [items]="navigationItems"
        (triggerCustomEvent)="handleCustomEvent()">
      </ume-navigation>
      <nav class="menu-right">
        @if (notUmeOps) {
          <button
            mat-button
            matTooltip="Help"
            [matMenuTriggerFor]="aboutMenu">
            Help
          </button>

          @defer (on idle) {
            <button
              *ngIf="(isNotifications$ | async) || ddqNotifications"
              mat-icon-button
              matTooltip="Notifications"
              class="notification-btn"
              [matMenuTriggerFor]="notifications">
              <mat-icon>notifications</mat-icon>
              <span class="sign"></span>
            </button>
          }
        }

        <ume-profile-menu></ume-profile-menu>
      </nav>
    </ume-main-nav-wrap>

    <main id="ume-content">
      <router-outlet></router-outlet>
    </main>
  } @else {
    <router-outlet></router-outlet>
  }
</div>

<mat-menu #aboutMenu="matMenu" xPosition="before">
  @for (item of helpItems; track item.label) {
    @if (item.external) {
      <a
        mat-menu-item
        [target]="item.target || '_blank'"
        [href]="item.link">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.name }}</span>
      </a>
    } @else {
      <a
        mat-menu-item
        target="_self"
        [routerLink]="item.link | companyTypeBasedRoute">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.name }}</span>
      </a>
    }
  }

  <button
    *ngFor="let file of termsAndConditions$ | async"
    mat-menu-item
    (click)="downloadTerms(file)">
    <mat-icon>picture_as_pdf</mat-icon>
    <span style="display: inline-block;"><p class="first-letter-upperCase">{{ file.name }}</p></span>
  </button>

  <button mat-menu-item (click)="welcomeGuide()">Welcome guide</button>
</mat-menu>

<mat-menu #notifications="matMenu" xPosition="before">
  <ume-user-notifications
    [isMenu]="true"
    [isVirtualPremium]="false"
    [selectedForAssessmentSummary]="0"
    [ddqSummary]="ddqSummary$ | async"
  >
  </ume-user-notifications>
</mat-menu>